@import '../../styles/variables';

.label-status {
  white-space: nowrap;
}

.account-role {
  font-weight: $font-weight-normal !important;
  padding: 8px 1rem !important;
  font-size: $font-size-base !important;
}

.account-role--sm {
  padding: 6px 10px !important;
  font-size: $font-size-sm !important;
}

.account-role--super_admin {
  background-color: #107ad9 !important;
}

.account-role--admin {
  background-color: $indigo !important;
}

.account-role--staff {
  background-color: $orange !important;
}

// Country
.label-country {
}

// Company Type
.company-type {
  padding: 8px 1rem;
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
}

.company-type--ab {
  background-color: #107ad9;
}

.company-type--admin {
  background-color: $indigo;
}

.company-type--staff {
  background-color: $orange;
}

.confidential-container {
  position: relative;

  .confidential-icon {
    margin-right: 4px;

    &--float {
      // position on the left middle side
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate((calc(-100% - 4px), -50%));

      @each $name, $breakpoint in $grid-breakpoints {
        @media only screen and (max-width: $breakpoint) {
          &-#{$name} {
            position: static;
            transform: none;
          }
        }
      }
    }
  }
}

.participation-status-badge {
  font-size: $font-size-base !important;
  font-weight: $font-weight-normal !important;
  word-wrap: break-word !important;
}
